import {
    ElMessage
} from 'element-plus'
import {
    nextTick
}
from 'vue'
export default {
    // 验证 材质 texture
    texture(rule, value, callback) {
        if (value) {
            callback()
        } else {
            setTimeout(() => {
                ElMessage.error('材质不能为空')
            }, 10)
            callback(new Error("材质不能为空"))
        }
    },
    // 验证 规格 specification
    specification(rule, value, callback) {
        if (value) {
            callback()
        } else {
            setTimeout(() => {
                ElMessage.error('规格不能为空')
            }, 10)
            callback(new Error("规格不能为空"))
        }
    },
    // 验证 钢厂 factory
    factory(rule, value, callback) {
        if (value) {
            callback()
        } else {
            setTimeout(() => {
                ElMessage.error('钢厂不能为空')
            }, 10)
            callback(new Error("钢厂不能为空"))
        }
    },

    // 验证 价格 price
    price(rule, value, callback) {
        if (value) {
            callback()
        } else {
            setTimeout(() => {
                ElMessage.error('价格不能为空')
            }, 10)
            callback(new Error("价格不能为空"))
        }
    },
    // 验证 仓库地址 address
    address(rule, value, callback) {
        if (value) {
            callback()
        } else {
            setTimeout(() => {
                ElMessage.error('仓库地址不能为空')
            }, 10)
            callback(new Error("仓库地址不能为空"))
        }
    },
    // 验证 仓库名称 addressName
    addressName(rule, value, callback) {
        if (value) {
            callback()
        } else {
            setTimeout(() => {
                ElMessage.error('仓库名称不能为空')
            }, 10)
            callback(new Error("仓库名称不能为空"))
        }
    },
    // 验证 质量等级 grade
    grade(rule, value, callback) {
        if (value) {
            callback()
        } else {
            setTimeout(() => {
                ElMessage.error('质量等级不能为空')
            }, 10)
            callback(new Error("质量等级不能为空"))
        }
    },
    // 验证 生产工艺 manufacturing
    manufacturing(rule, value, callback) {
        if (value) {
            callback()
        } else {
            setTimeout(() => {
                ElMessage.error('生产工艺不能为空')
            }, 10)
            callback(new Error("生产工艺不能为空"))
        }
    },
    description(rule, value, cb) {
        if (value) {
            cb()
        } else {
            setTimeout(() => {
                ElMessage.error('品名不能为空')
            }, 10)
            cb(new Error("品名不能为空"))
        }
    },

    // 发布商机的校验
    name(rule, value, cb) {
        if (value) {
            cb()
        } else {
            setTimeout(() => {
                ElMessage.error('标题不能为空')
            }, 10)
            cb(new Error("标题不能为空"))
        }
    },
    column_id(rule, value, cb) {
        if (value) {
            cb()
        } else {
            setTimeout(() => {
                ElMessage.error('标签不能为空')
            }, 10)
            cb(new Error("标签不能为空"))
        }
    },

    desc(rule, value, cb) {
        if (value) {
            cb()
        } else {
            setTimeout(() => {
                ElMessage.error('正文不能为空')
            }, 10)
            cb(new Error("正文不能为空"))
        }
    }


}