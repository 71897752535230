
import http from '../axios'


// 发布商机
export const $business = (params) => {
    return http.post('/issue/news', params)
}

// 发布商机里面的图片
export const $uploadImg = params => {
    return http.post('/add/upload', params)
}

// 添加报价的接口

export const $addPrice = params => {
    return http.post('/add/price', params)
}